<template>
  <section  class="uk-section uk-section-secondary uk-section-large">
    <div class="uk-container">

        <div class="no-print uk-margin-medium-top ">

        </div>
        
    <div uk-grid class="uk-grid-match">
        <div  class="uk-width-1-4 print-only" style="background-color:#555!important;">
            <img :src="logo" class="uk-margin-medium-right" alt="logo" >
            <p style="color:white!important"> CANNONSOFT <br>
                Automatizamos lo aburrido.  <br><br>
                FECHA: <span style="color:white!important" v-if="id">{{new Date(id).toLocaleDateString()}}</span><span style="color:white!important" v-else>{{new Date().toLocaleDateString()}}</span>
            </p>
            <h3 style="color:white!important">¡BIENVENIDOS! &nbsp;</h3>
        </div> 
        <div class="uk-width-3-4 uk-light">

            <h1 class="uk-text-left">Cotización: {{cotizacion.nombre}}</h1>
            <table class="uk-table uk-table-striped uk-table-responsive">
                <thead>
                    <tr class="purple-bg">
                        <th>
                            CANTIDAD
                        </th>
                        <th>
                            DESCRIPCIÓN DEL SERVICIO
                        </th>
                        <th>
                            PRECIO ({{cotizacion.currency}})
                        </th>
                        <th>
                            SUBTOTAL
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item, index in cotizacion.servicios">
                        <td>{{item.cantidad}}</td>
                        <td>{{item.nombre}}
                            <p v-if="item.subitems">
                                <table class="uk-table uk-table-small">
                                    <tr v-for="subitem,subindex in subitems">
                                        <td>{{subitem.nombre}}</td>
                                        <td>${{subitem.precio}}</td>
                                    </tr>
                                </table>
                            </p>
                        </td>
                        <td>${{item.precio}}</td>
                        <td>{{subtotal(index)}}</td>
                    </tr>
                    <tr>
                        <td></td><td></td>
                        <td><b>TOTAL*</b></td>
                        <td><b>{{total}}</b></td>
                    </tr>
                </tbody>
            </table>
            <table class="uk-table uk-table-striped uk-table-responsive">
                <thead>
                    <tr class="purple-bg">
                        <th>
                            MENSUALIDAD DE SERVICIOS DE CANNONSOFT**
                        </th>
                        <th>
                            PRECIO MENSUAL ({{cotizacion.currency}})
                        </th>
                        <th>
                            SUBTOTAL
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item,index in cotizacion.mensualidad">
                        <td>
                            {{item.nombre}} 
                        </td>
                        <td>
                            ${{item.precio}}
                        </td>
                    </tr>
                    <tr>
                        <td></td><td></td>
                        <td>
                            MENSUALIDAD TOTAL**
                        </td>
                        <td>
                            {{mensualidad()}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <p class="uk-text-justify">*LOS COSTOS EXPRESADOS EN ESTA CUENTA NO INCLUYEN MENSUALIDADES SUBSECUENTES A 
            LAS CUALES ESTÁN SUJETOS TODOS LOS SERVCIOS DE APLICATIVOS DIGITALES 
            DESARROLLADOS POR CANNONSOFT <br><br>
            **LA MENSUALIDAD DE SERVICIOS CANNONSOFT DEBE CUBRIRSE CADA PERIODO PARA 
            MANTENER EL CORRECTO FUNCIONAMIENTO DE TODOS LOS APLICATIVOS DIGITALES 
            DESARROLLADOS POR CANNONSOFT, EL COSTO DEPEENDE Y PUEDE VARIAR AL MODIFICAR LAS 
            FUNCIONES DEL APLICATIVO</p>
        </div> 
    </div>
    </div>
    </section>
</template>

<style scoped>

.print-only{
  display: none;
}
@media print
{    

    .no-print, .no-print *
    {
        display: none !important;
        padding: 0%;
    }
    .print-only{
      display: block;
      -webkit-print-color-adjust: exact !important;
    }
    
    #nav {
      padding: 0px;
    }
    h1 {
      padding: 0px;
    }
    h3 {
      padding: 0px;
    }
}
</style>

<script>
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { computed } from '@vue/runtime-core'
export default {
    setup(){
        const cotizacion = ref({})
        const logo = require('@/assets/logo.png')
        const route = useRoute()
        const id = ref('')

        if(route.params.id){
            id.value = route.params.id
            firebase.database().ref('site/cotizaciones/'+id.value).once('value').then((s)=>{
                if(s.exists()){
                    cotizacion.value = s.toJSON()
                }
                
            })
        }



        const total = computed(()=>{
            let tot = 0.0
            for(let i in cotizacion.value.servicios){
                if(cotizacion.value.servicios[i].precio) tot += cotizacion.value.servicios[i].precio
            }
            tot = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tot.toFixed(2)) 
            return tot
        })
        const subtotal = (index)=>{
            let tot = 0.0
            for(let i in cotizacion.value.servicios){
                if(cotizacion.value.servicios[i].precio) tot += cotizacion.value.servicios[i].precio
                if(i == index) break
            }
            tot = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tot.toFixed(2)) 
            return tot
            
        }
        const mensualidad = ()=>{
            let tot = 0.0
            for(let i in cotizacion.value.mensualidad){
                if(cotizacion.value.mensualidad[i].precio) tot += cotizacion.value.mensualidad[i].precio
            }
            tot = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tot.toFixed(2)) 
            return tot
        }

        return {
            cotizacion,
            logo,
            id,

            total,
            subtotal,
            mensualidad,
        }
    }
}
</script>

<style>

</style>